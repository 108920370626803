import React, { Component } from 'react'
import {
  Empty,
  Grid,
  DatePicker,
  Radio,
  Space,
  Card,
  Typography,
  Divider,
  Link,
  Input,
  Button,
  Select,
  Message,
  Layout,
  Tabs,
  Form,
  Descriptions,
  Upload,
  Notification,
  InputNumber,
  Spin,
} from '@arco-design/web-react'
import { IconCopy, IconUpload, IconDelete } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import copy from 'copy-to-clipboard'
import history from 'common/utils/history'
import upload from 'api/uploads'
import { getParams, postDebug, check_resource, getAllapiList, newGetappList, newEditappList, newRefreshToken } from 'api/apis'
import ALLURL from 'envconfig/config'
import './interfacetest.scss'
import UpgradeTips from 'components/UpgradeTips'
import { localAxios } from "api/request";

const FormItem = Form.Item
const RadioGroup = Radio.Group
const Row = Grid.Row
const Col = Grid.Col
const RangePicker = DatePicker
const InputSearch = Input.Search
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea
const TabPane = Tabs.TabPane

class Interfacetest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingbody: true,
      SearchValue: ALLURL.baseURL + '/v2/api/request',
      Tablist: ['接口测试', '参数说明'],
      type: '接口测试',
      userinfo: JSON.parse(localStorage.getItem('userinfo')),
      fileList: null,
      attachment: [],
      record: JSON.parse(localStorage.getItem('record')),
      business_parameters: [],
      global_params: {
        access_token:JSON.parse(localStorage.getItem('userinfo')).access_key || '',
        access_secret:JSON.parse(localStorage.getItem('userinfo')).access_secret || '',
      },
      initialValues: {},
      visibleUpgrade: false,
      upgradetips: null,
      interfaceOption:[],
      postValue:'',
      postData:[],
      api_id:'',
      apiValue:'',
      request_param: '',
      Specification: [
        // {
        //   key: 'AccessSecret',
        //   required: true,
        //   type: 'text',
        //   desc: '授权令牌',
        // },
        // {
        //   key: 'AccessToken',
        //   required: true,
        //   type: 'text',
        //   desc: '授权密钥',
        // },
        {
          key: 'Token',
          required: true,
          type: 'text',
          desc: 'token',
        },
        // {
        //   key: 'app_id',
        //   required: true,
        //   type: 'text',
        //   desc: '应用id',
        // },
      ],
      SpecificationList: [],
      RequestResponse: null,
      RequestResponseValue:null,
      paramsLength:0,
      model_capability_name: '',
      apiToken:'',
      apiInfo: {
        model_id: '',
        app_name:'',
        type:9,
        app_desc:'',
      },
    }
    this.formRef = React.createRef()
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    const HeaderInitHeight = document.getElementById('Header_init')
      ? document.getElementById('Header_init').clientHeight
      : 0
    const hedSearchHeight = document.getElementById('hedSearch')
      ? document.getElementById('hedSearch').clientHeight
      : 0
    let height =
      winHeight - layoutHeaders_h - HeaderInitHeight - hedSearchHeight - 80
    if (document.getElementById('order_id')) {
      document.getElementById('order_id').style.height = height + 'px'
    }
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }
  booWho(bool) {
    return typeof bool === 'boolean'
  }
  ArrayConversion() {
    const { Specification, SpecificationList } = this.state
    Specification.map((item, i) => {
      const Preset_Data = [
        {
          label: '必填',
          value: item.required ? '是' : '否',
        },
        {
          label: '类型',
          value: item.type,
        },
        {
          label: '描述',
          value: item.desc,
        },
      ]
      let Specification_obj = {
        data: Preset_Data,
        title: item.key,
      }
      SpecificationList.push(Specification_obj)
    })
    this.setState({
      SpecificationList: SpecificationList,
    })
  }
  setInitialValues(arr) {
    let newArr = {}
    if (arr) {
      arr.forEach((item, i) => {
        if (item.key) {
            let defaultValue = item.default;
            if (item.type === 'array') {
                defaultValue = JSON.stringify(defaultValue, null, 2); // 将数组转换为字符串  第三个参数为缩进空格数
            }
            newArr[item.key] = String(defaultValue);
        }
    });
    return newArr;
    }
  }
  getApiInfo(){
    const { record, interfaceOption } = this.state;
    newGetappList({id:record.show_id}).then((res) => {
      if(res.code === 200){
        res.data.api_list.forEach((item,index) => {
          let api_option = {
            value:'',
            label:'',
            api_id:''
          }
          api_option.value = index;
          api_option.label = item.name;
          api_option.api_id = item.api_id;
          interfaceOption.push(api_option)
        })
        // console.log(interfaceOption);
        this.setState({
          interfaceOption:interfaceOption,
          api_id: res.data.api_list[0].api_id,
          apiValue: res.data.api_list[0].name,
          apiToken:res.data.token,
        })
        this.state.apiInfo.app_name = res.data.name;
        this.state.apiInfo.app_desc = res.data.desc;
        this.getInterfaceParameter()
      } else {
        Notification.warning({ content: res.msg });
        this.setState({
            loadingbody: false,
        });
      }
      
    })
  }
  openInterface(value){
    console.log(value,this.state.postData,'777');
    this.setState({
      api_id:this.state.interfaceOption[value].api_id,
      apiValue:this.state.interfaceOption[value].value
    })
    console.log(this.state.interfaceOption[value].api_id);
    this.getInterfaceParameter()
  }
  setRequestHeader(){
    const { postValue,paramsLength, global_params } = this.state;
    let headers = {
      "method": "POST",
      "url": ALLURL.baseURL + postValue,
      "header": {
        "host": [
          ALLURL.baseURL
        ],
        // "x-real-ip": [
        //   "120.238.255.141"
        // ],
        "x-scheme": [
          "https"
        ],
        // "x-forwarded-for": [
        //   "120.238.255.141"
        // ],
        "connection": [
          "close"
        ],
        "content-length": [
          paramsLength
        ],
        "pragma": [
          "no-cache"
        ],
        "cache-control": [
          "no-cache"
        ],
        "sec-ch-ua": [
          "\"Microsoft Edge\";v=\"113\", \"Chromium\";v=\"113\", \"Not-A.Brand\";v=\"24\""
        ],
        "accesstoken": [
          global_params.access_token
        ],
        "sec-ch-ua-mobile": [
          "?0"
        ],
        "authorization": [
          localStorage.getItem('token') ? localStorage.getItem('token') : ''
        ],
        "user-agent": [
          "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36 Edg/113.0.1774.57"
        ],
        "content-type": [
          "application/json;charset=UTF-8"
        ],
        "timestamp": [
          parseInt(new Date().getTime() / 1000)
        ],
        "signature": [
          localStorage.getItem('Signature') ? localStorage.getItem('Signature') : ''
        ],
        "accesssecret": [
          global_params.access_secret
        ],
        "appid": [
          "RzMKtFCAH5"
        ],
        "sec-ch-ua-platform": [
          "\"Windows\""
        ],
        "accept": [
          "*/*"
        ],
        "origin": [
          ALLURL.baseURL
        ],
        "sec-fetch-site": [
          "same-site"
        ],
        "sec-fetch-mode": [
          "cors"
        ],
        "sec-fetch-dest": [
          "empty"
        ],
        "referer": [
          ALLURL.baseURL
        ],
        "accept-encoding": [
          "gzip, deflate, br"
        ],
        "accept-language": [
          "zh-CN,zh;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6"
        ]
      }
    };
    this.setState({
      RequestResponse: headers
    })
  }
  getInterfaceParameter() {
    const { api_id, SpecificationList, business_parameters } = this.state;

    let params = {
        id: api_id,
        // id: '95YT1z',
    };

    getAllapiList(params).then((res) => {
        if (res.code === 200) {
            const postDatas = res.data.api_params.post;
            const requestData = postDatas.requestBody.content["application/json"];

            this.processParameters(postDatas.parameters, SpecificationList);
            this.processSchemaProperties(requestData.schema.properties, SpecificationList, requestData.schema.required);
            this.processExample(requestData.example, business_parameters);

            this.setState({
                business_parameters: business_parameters,
                initialValues: this.setInitialValues(business_parameters),
                postValue:res.data.api_url,
                request_param:JSON.stringify(requestData.example),
                RequestResponseValue: postDatas.responses['200'].content['application/json'].examples['1'].value,
                paramsLength:JSON.stringify(requestData.example).length
            });
            this.setRequestHeader()

            this.setState({
                SpecificationList: SpecificationList,
                loadingbody: false,
            });

        } else {
            Notification.warning({ content: res.msg });
            this.setState({
                loadingbody: false,
            });
        }
    });
}

processParameters(parameters, specificationList) {
    parameters.forEach((item) => {
        const Preset_Data = [
            {
                label: '必填',
                value: item.required ? '是' : '否',
            },
            {
                label: '类型',
                value: item.schema.type,
            },
            {
                label: '描述',
                value: item.description,
            },
        ];

        const Specification_obj = {
            data: Preset_Data,
            title: item.name,
        };

        specificationList.push(Specification_obj);
    });
}

processSchemaProperties(properties, specificationList, requestList) {
    Object.keys(properties).forEach((key) => {
        const Preset_Data = [
            {
                label: '必填',
                value: requestList.includes(key) ? '是' : '否',
            },
            {
                label: '类型',
                value: properties[key].type,
            },
            {
                label: '描述',
                value: properties[key].description,
            },
        ];

        const Specification_obj = {
            data: Preset_Data,
            title: key,
        };

        specificationList.push(Specification_obj);

        if (properties[key].type === 'object') {
            this.processNestedProperties(properties[key].properties, specificationList, key, properties);
        }
    });
}

processNestedProperties(nestedProperties, specificationList, parentKey,properties) {
    Object.keys(nestedProperties).forEach((key2) => {
      
        const Preset_Data2 = [
            {
                label: '必填',
                value: properties[parentKey].required.includes(key2) ? '是' : '否',
            },
            {
                label: '类型',
                value: nestedProperties[key2].type,
            },
            {
                label: '描述',
                value: nestedProperties[key2].description || `归属上级${parentKey}`,
            },
        ];

        const Specification_obj = {
            data: Preset_Data2,
            title: key2,
        };

        specificationList.push(Specification_obj);
    });
}

processExample(example, businessParameters) {
    Object.keys(example).forEach((key3) => {
        const params = {
            default: example[key3],
            desc: "",
            key: key3,
            "type-backend": "text",
            type: this.getSpecificationListDetail(key3)?.data?.[1]?.value,
            required: this.getSpecificationListDetail(key3)?.data?.[0]?.value === "是",
        };

        if (this.getSpecificationListDetail(key3)?.data?.[1]?.value !== "object") {
            businessParameters.push(params);
        } else {
            Object.keys(example[key3]).forEach((key4) => {
                const params2 = {
                    default: example[key3][key4],
                    desc: "",
                    key: key4,
                    "type-backend": "text",
                    type: this.getSpecificationListDetail(key4)?.data?.[1]?.value,
                    required: this.getSpecificationListDetail(key4)?.data?.[0]?.value === "是",
                };

                businessParameters.push(params2);
            });
        }
    });
}
  // getInterfaceParameter() {
  //   const { record, Specification, SpecificationList, business_parameters } = this.state
  //   let params = {
  //     // app_id: record.id, 
  //     app_id: record.show_id,
  //   }
  //   getAllapiList({id: '95YT1z'}).then((res) => {
  //     console.log(res,'777');
  //     if (res.code === 200) {
  //       const postDatas = res.data.api_params.post
  //       const requestData = postDatas.requestBody.content["application/json"]
  //       postDatas.parameters.map((item, i) => {
  //           const Preset_Data = [
  //             {
  //               label: '必填',
  //               value: item.required ? '是' : '否',
  //             },
  //             {
  //               label: '类型',
  //               value: item.schema.type,
  //             },
  //             {
  //               label: '描述',
  //               value: item.description,
  //             },
  //           ]
  //           let Specification_obj = {
  //             data: Preset_Data,
  //             title: item.name,
  //           }
  //           SpecificationList.push(Specification_obj)
  //         })
  //         for (let key in requestData.schema.properties) {
  //           const Preset_Data = [
  //             {
  //               label: '必填',
  //               value: requestData.schema.required.includes(key) ? '是' : '否',
  //             },
  //             {
  //               label: '类型',
  //               value: requestData.schema.properties[key].type,
  //             },
  //             {
  //               label: '描述',
  //               value: requestData.schema.properties[key].description,
  //             },
  //           ]
  //           let Specification_obj = {
  //             data: Preset_Data,
  //             title: key,
  //           }
          
  //           SpecificationList.push(Specification_obj)
  //           if(requestData.schema.properties[key].type == 'object'){
  //             for (let key2 in requestData.schema.properties[key].properties) {
  //               const Preset_Data2 = [
  //                 {
  //                   label: '必填',
  //                   value: requestData.schema.properties[key].required.includes(requestData.schema.properties[key].properties[key2]) ? '是' : '否',
  //                 },
  //                 {
  //                   label: '类型',
  //                   value: requestData.schema.properties[key].properties[key2].type,
  //                 },
  //                 {
  //                   label: '描述',
  //                   value: requestData.schema.properties[key].properties[key2]?.desc || `归属上级${key}`,
  //                 },
  //               ]
  //                Specification_obj = {
  //                 data: Preset_Data2,
  //                 title: key2,
  //               }
  //               SpecificationList.push(Specification_obj)
  //             }
  //           }
  //         }
  //         for (let key3 in requestData.example) {
  //           let params = {
  //             default:requestData.example[key3],
  //             desc:"",
  //             key:key3,
  //             "type-backend":"text",
  //             type:this.getSpecificationListDetail(key3).data[1].value,
  //             required:this.getSpecificationListDetail(key3).data[0].value == "是"
  //           }
  //           if(this.getSpecificationListDetail(key3).data[1].value != "object"){
  //             business_parameters.push(params)
  //           } else {
  //             for (let key4 in requestData.example[key3]) {
  //               let params2 = {
  //                 default:requestData.example[key3][key4],
  //                 desc:"",
  //                 key:key4,
  //                 "type-backend":"text",
  //                 type:this.getSpecificationListDetail(key4).data[1].value,
  //                 required:this.getSpecificationListDetail(key4).data[0].value == "是"
  //               }
  //               business_parameters.push(params2)
  //           }
  //           }
  //         }
  //         this.setState({
  //           business_parameters: business_parameters,
  //           initialValues:this.setInitialValues(business_parameters),
  //         })
  //         this.setState({
  //           SpecificationList: SpecificationList,
  //           loadingbody: false,
  //         })
  //         console.log(SpecificationList,business_parameters,'777');
  //       } else {
  //         Notification.warning({ content: res.msg })
  //         this.setState({
  //           loadingbody: false,
  //         })
  //       }
  //   })

  // }
  
      // getParams(params).then((res) => {
    //   if (res.code === 200) {
    //     if (res.data) {
    //       this.setState({
    //         business_parameters: res.data.business_params,
    //         global_params: res.data.global_params,
    //         SearchValue: res.data.interface_url,
    //         initialValues: this.setInitialValues(res.data.business_params),
    //         Specification: Specification.concat(res.data.business_params),
    //         model_capability_name: res.data.label,
    //         loadingbody: false,
    //       })
    //       this.formRef.setFieldsValue(
    //         this.setInitialValues(res.data.business_params)
    //       )
    //       this.ArrayConversion()
    //       setTimeout(() => {
    //         this.handleResize()
    //       }, 100)
    //     }
    //   } else {
    //     Notification.warning({ content: res.msg })
    //     this.setState({
    //       loadingbody: false,
    //     })
    //   }
    // })
  getSpecificationListDetail(name){
    const { SpecificationList } = this.state;
    const detail = SpecificationList.find(item => item.title === name);
    return detail;
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 32) {
        apiInfo.app_desc = value.substring(0, 32)
        Notification.warning({ content: '修改内容超过32个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.putapp(false, value)
    } else if (type === 'name') {
      if (value.length > 10) {
        apiInfo.app_name = value.substring(0, 10)
        Notification.warning({ content: '修改标题超过10个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.putapp(value, false)
    }
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.show_id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    newEditappList(params).then((res) => {
      if (res.code === 200) {
      } else {
        Notification.error({ content: res.msg })
      }
    })
  }
  postDebug_Params(params) {
    postDebug(params).then((res) => {
      if (res.code === 200) {
        this.setState({
          RequestResponse: res.data,
        })
      } else {
        Notification.warning({ content: res.msg })
      }
    })
  }
  getFormRefFieldsValue() {
    let data = this.formRef.getFieldsValue()
    // ES6-使用js删除对象中带有null和undefined值的数据
    let obj = Object.keys(data)
      .filter(
        (key) =>
          data[key] !== null &&
          data[key] !== undefined &&
          data[key] !== '' &&
          data[key].length !== 0
      )
      .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
    return obj
  }
  formRefDate() {
    const { record } = this.state
    // ES6-使用js删除对象中带有null和undefined值的数据
    let obj = this.getFormRefFieldsValue()
    obj.app_id = record.id
    let params = obj
    console.log(obj)
    this.postDebug_Params(params)
  }
  onsetPosition(value) {
    this.setState({
      type: value,
    })
    this.state.type = value
  }
  oncopy(value) {
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  handleUpload(option) {
    upload(option)
  }
  setFile(Item, fileList, file) {
    this.setState({
      fileList: fileList,
    })
    if (file.status === 'done') {
      const newValues = this.getFormRefFieldsValue()
      let key = []
      key.push(file.response.fileKey)
      newValues[Item.key] = file.response.url
      this.setState({
        attachment: key,
        initialValues: newValues,
      })
      this.formRef.setFieldsValue(newValues)
    }
  }
  onIconDelete(Item) {
    const newValues = this.getFormRefFieldsValue()
    newValues[Item.key] = ''
    this.setState({
      fileList: null,
      attachment: [],
      initialValues: newValues,
    })
    this.formRef.setFieldsValue(newValues)
  }
  gomanagement() {
    history.push('/enginefactory/model/management')
    window.location.reload()
  }
  setTablist = () => {
    const { SpecificationList, type, RequestResponse, RequestResponseValue } = this.state
    return type === '接口测试' ? (
      <div className="Tabsbody-ul">
        <div className="tabli">
          <Typography.Text>请求信息</Typography.Text>
          {RequestResponse ? (
            <div className="tabText">
              <div className="tabText-overflow">
                <div>请求方法：{RequestResponse?.method}</div>
                <div>请求url：{RequestResponse?.url}</div>
                <div>请求header：</div>
                {Object.keys(RequestResponse.header).map((item, i) => {
                  return (
                    <div key={i}>
                      <span>{item}:</span>
                      <span>{RequestResponse.header[item]}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <Empty
              className="tabli-none"
              imgSrc={Emptydata_img}
              description={
                <Space direction="vertical">
                  <Typography
                    style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                  >
                    输入参数，获取请求信息
                  </Typography>
                </Space>
              }
            />
          )}
        </div>
        <div className="tabli">
          <Typography.Text>响应数据</Typography.Text>
          {RequestResponseValue ? (
            <div className="tabText">
              <div
                className="tabText-overflow"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(RequestResponseValue),
                }}
              ></div>
            </div>
          ) : (
            <Empty
              className="tabli-none"
              imgSrc={Emptydata_img}
              description={
                <Space direction="vertical">
                  <Typography
                    style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                  >
                    输入参数，获取响应数据
                  </Typography>
                </Space>
              }
            />
          )}
        </div>
      </div>
    ) : type === '签名规范' ? (
      <div>
        <div className="tabli">
          <Typography.Text>js实现版</Typography.Text>
          <Empty
            className="tabli-none"
            imgSrc={Emptydata_img}
            description={
              <Space direction="vertical">
                <Typography
                  style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                >
                  输入参数，获取请求信息
                </Typography>
              </Space>
            }
          />
        </div>
        <div className="tabli">
          <Typography.Text>php实现版</Typography.Text>
          <Empty
            className="tabli-none"
            imgSrc={Emptydata_img}
            description={
              <Space direction="vertical">
                <Typography
                  style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                >
                  输入参数，获取请求信息
                </Typography>
              </Space>
            }
          />
        </div>
      </div>
    ) : type === '参数说明' ? (
      <div className="description">
        {SpecificationList.map((item, i) => {
          return (
            <Descriptions
              key={i}
              column={1}
              colon=" :"
              layout="inline-horizontal"
              title={item.title}
              data={item.data}
            />
          )
        })}
      </div>
    ) : null
  }
  getCheckResource() {
 
    const { postValue, request_param, paramsLength } = this.state
    // 解析 request_param
    const requestParamObj = JSON.parse(request_param);
    let postApi = localAxios("post", postValue);
    let formData = this.getFormRefFieldsValue()
    // 更新 requestParamObj
    const updatedRequestParam = this.updateRequestParam(formData, requestParamObj);
    console.log(postValue,formData,JSON.parse(request_param),updatedRequestParam);
    const paramslength = JSON.stringify(updatedRequestParam).length;
    postApi(updatedRequestParam).then(res=>{
      console.log(res);
    })
    this.setState({
      paramsLength: paramslength
    })
    // check_resource(params).then((res) => {
    //   if (res.code === 200) {
    //     if (res.data) {
    //       let new_upgradetips = res.data
    //       new_upgradetips.model_capability = params.model_capability
    //       this.setState({
    //         upgradetips: new_upgradetips,
    //       })
    //       if (PACKAGES[new_upgradetips.notice_type]) {
    //         Notification.warning({ content: new_upgradetips.message })
    //       } else {
    //         setTimeout(() => {
    //           this.setState({
    //             visibleUpgrade: true,
    //           })
    //         }, 100)
    //       }
    //     } else {
    //       this.formRefDate()
    //     }
    //   } else {
    //     Notification.warning({ content: res.msg })
    //   }
    // })
  }
  // 更新 requestParamObj 的值
  updateRequestParam(initialValues, requestParamObj) {
    // 更新基本字段
    Object.keys(initialValues).forEach(key => {
        if (key === 'catalogs' || key === 'contents') {
            // 特殊处理 catalogs 和 contents 字段
            requestParamObj.custom_data[key] = JSON.parse(initialValues[key]);
        } else {
            // 直接赋值
            requestParamObj[key] = initialValues[key];
        }
    });

    return requestParamObj;
  }
  
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  openWiki() {
    window.open(
      'https://wiki.yoo-ai.com/#/wernicke/api/?id=' +
        this.state.model_capability_name
    )
  }
  openResources(){
    history.push('/myapp/calldetails');
    window.location.reload();
  }
  refreshToken() {
    
    newRefreshToken({id:this.state.record.show_id}).then(res => {
      if(res.code === 200) {
        this.setState({
          apiToken:res.data.token
        })
        Notification.success({ content: '刷新成功' })
        // window.location.reload();
      } else {
        Notification.error({ content: res.msg })
      }
    })
  }
  render() {
    const {
      loadingbody,
      Tablist,
      SearchValue,
      type,
      userinfo,
      fileList,
      url,
      business_parameters,
      global_params,
      initialValues,
      visibleUpgrade,
      upgradetips,
      record,
      interfaceOption,
      postData,
      postValue,
      apiValue,
      apiInfo,
      apiToken,
    } = this.state
    return (
      <div className="interfacetest">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="Header-init" id="Header_init">
              <div className="Header-title">接口调用</div>
            </div>
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="GroupTable-fixe" id="hedSearch">
              <Radio.Group
                type="button"
                name="direction"
                value={type}
                onChange={this.onsetPosition.bind(this)}
                options={Tablist}
                className="GroupTable"
              ></Radio.Group>
              <div>
                <Space size={'large'}>
                  {/* <Space align={'center'}>
                    <Typography.Text className="interfacetest-fixetitle">
                      请求方法：POST
                    </Typography.Text>
                    <div className="interfacetest-fixetitle interfacetest-api">
                      接口地址：
                      <Typography.Paragraph copyable>
                        {SearchValue}
                      </Typography.Paragraph>
                    </div>
                  </Space> */}
                  {/* <Space align={'center'} size={'medium'}>
                    <Button
                      shape="round"
                      className="primary-white custom-White-button"
                      onClick={this.openWiki.bind(this)}
                    >
                      API文档
                    </Button>
                  </Space>
                </Space> */}
                <Space align={'center'} size={'medium'}>
                    <Button
                      shape="round"
                      className="primary-white custom-White-button"
                      onClick={this.openResources.bind(this)}
                    >
                      调用明细
                    </Button>
                  </Space>
                  <Space align={'center'} size={'medium'}>
                    <Button
                      shape="round"
                      className="primary-white custom-White-button"
                      onClick={this.refreshToken.bind(this)}
                    >
                      刷新Token
                    </Button>
                  </Space>
                </Space>
              </div>
            </div>
            <div id="order_id">
              <Layout style={{ height: '100%' }}>
                <Content
                  className={`Work-Content ${
                    type === `接口测试` ? `` : `set-margins`
                  }`}
                >
                  <div className="Tabsbody">{this.setTablist()}</div>
                </Content>
                <Sider className="Sider-left">
                  <div className="debugg-sider">
                    {/* 暂不开放 */}
                    {/* <div className="rowSpaceBetween">
                  <Typography.Text className="debugg-title">
                    模型版本
                  </Typography.Text>
                  <Button
                    type="outline"
                    className="outline"
                    onClick={this.gomanagement.bind(this)}
                  >
                    模型管理
                  </Button>
                </div> */}
                    <div className="debugg-sider-input tabText-overflow">
                      <Form
                        style={{ width: '100%' }}
                        ref={(ref) => (this.formRef = ref)}
                        layout="vertical"
                        initialValues={initialValues}
                      >
                        {/* 暂不开放 */}
                        {/* <Form.Item label="版本" field="version">
                      <Select
                        allowClear
                        placeholder="请选择模型版本"
                        options={["1.v", "2.v"]}
                      ></Select>
                    </Form.Item> */}
                        <FormItem className="FormItem-debugg-title">
                          <div className="debugg-title">全局参数</div>
                        </FormItem>
                        <FormItem label="Token" field="token">
                          <Space>
                            <Input
                              placeholder=""
                              value={apiToken}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                apiToken
                              )}
                            />
                          </Space>
                        </FormItem>
                        {/* <FormItem label="AccessSecret" field="access_secret">
                          <Space>
                            <Input
                              placeholder=""
                              value={global_params.access_secret}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                global_params.access_secret
                              )}
                            />
                          </Space>
                        </FormItem> */}
                        {/* <FormItem label="AccessToken" field="access_token">
                          <Space>
                            <Input
                              placeholder=""
                              value={global_params.access_token}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              shape="circle"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                global_params.access_token
                              )}
                            />
                          </Space>
                        </FormItem> */}
                        {/* <FormItem label="app_id" field="app_id">
                          <Space>
                            <Input
                              placeholder=""
                              value={record.id}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              shape="circle"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(this, record.id)}
                            />
                          </Space>
                        </FormItem> */}

                        <FormItem className="FormItem-debugg-title">
                          <div className="debugg-title">接口信息</div>
                        </FormItem>
                        <FormItem label="接口选项" field="access_token">
                        {/* <Select
                          placeholder='Select city'
                          style={{ width: 154 }}
                          onChange={(value) =>
                            Message.info({
                              content: `You select ${value}.`,
                              showIcon: true,
                            })
                          }
                        >
                          {options.map((option, index) => (
                            <Option key={option} disabled={index === 3} value={option}>
                              {option}
                            </Option>
                          ))}
                        </Select> */}
                        {
                          interfaceOption.length >= 1 ?
                          <>
                            <Select
                            allowClear
                            placeholder="请选择接口"
                            value={apiValue}
                            onChange={(value) =>
                              this.openInterface(value)
                              }
                              options={interfaceOption}
                            ></Select>
                          </>
                          : <><div className="debugg-title">暂无接口</div></>
                        }
                     
                        </FormItem>
                        <FormItem label="Post接口" field="access_token">
                          <Space>
                            <Input
                              placeholder=""
                              value={postValue}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              shape="circle"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                global_params.access_token
                              )}
                            />
                          </Space>
                        </FormItem>

                        <FormItem className="FormItem-debugg-title">
                          <div className="debugg-title">业务参数</div>
                        </FormItem>
                        {business_parameters?.map((Item, Item_index) => {
                          return (
                            <div key={Item_index}>
                              {Item['type-backend'] === 'text' &&
                              (Item.key !== 'fileKey' && Item.type !== 'array') ? (
                                <>
                                  <FormItem label={Item.key} field={Item.key}>
                                    <Input placeholder={Item.desc} />
                                  </FormItem>
                                </>
                              ) : Item['type-backend'] === 'text' &&
                              Item.type == 'array' ? (
                                <>
                                  <FormItem label={Item.key} field={Item.key}>
                                    <TextArea autoSize placeholder={Item.desc} />
                                  </FormItem>
                                </>
                              ): Item['type-backend'] === 'number' ? (
                                <>
                                  <FormItem label={Item.key} field={Item.key}>
                                    <InputNumber
                                      min={0}
                                      placeholder={Item.desc}
                                      hideControl
                                    />
                                  </FormItem>
                                </>
                              ) : Item['type-backend'] === 'select' ? (
                                <>
                                  <FormItem label={Item.key} field={Item.key}>
                                    <Select
                                      allowClear
                                      placeholder={Item.desc}
                                      options={Item.select_backend}
                                      getPopupContainer={(triggerNode) =>
                                        triggerNode.parentNode
                                      }
                                    ></Select>
                                  </FormItem>
                                </>
                              ) : Item['type-backend'] === 'text' &&
                                Item.key === 'fileKey' ? (
                                <Space>
                                  <Form.Item label={Item.key} field={Item.key}>
                                    <Input
                                      placeholder={Item.desc}
                                      readOnly
                                      className="readOnly"
                                    />
                                  </Form.Item>
                                  <div className="field_upload">
                                    {initialValues[Item.key] ? (
                                      <Button
                                        icon={<IconDelete />}
                                        className="Form-btnicon"
                                        onClick={this.onIconDelete.bind(
                                          this,
                                          Item
                                        )}
                                      />
                                    ) : (
                                      <Upload
                                        multiple
                                        limit="1"
                                        showUploadList={false}
                                        fileList={fileList}
                                        accept=""
                                        onChange={this.setFile.bind(this, Item)}
                                        customRequest={(option) => {
                                          this.handleUpload(option)
                                        }}
                                        onExceedLimit={() => {
                                          Message.warning(
                                            '超过上传数量限制！最多上传1个'
                                          )
                                        }}
                                      >
                                        <Button
                                          icon={<IconUpload />}
                                          className="Form-btnicon"
                                        />
                                      </Upload>
                                    )}
                                  </div>
                                </Space>
                              ) : Item['type-backend'] === 'textarea' ? (
                                <>
                                  <FormItem label={Item.key} field={Item.key}>
                                    <TextArea placeholder={Item.desc} />
                                  </FormItem>
                                </>
                              ) : null}
                            </div>
                          )
                        })}
                      </Form>
                    </div>
                  </div>
                  <div className="request_button">
                    <Divider />
                    <Button
                      type="primary"
                      className="Initiate-request"
                      onClick={this.getCheckResource.bind(this)}
                    >
                      发起请求
                    </Button>
                  </div>
                </Sider>
              </Layout>
            </div>
          </>
        )}
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}

export default Interfacetest
