import React, { Component } from "react";
import {
  Table,
  Empty,
  Card,
  Button,
  Tag,
  Modal,
  Form,
  Radio,
  Checkbox,
  Input,
  Notification,
  Link,
  Steps,
  Typography,
  Spin,
} from "@arco-design/web-react";
import { formatMoney, hidePhone } from "common/utils";
import history from "common/utils/history";
import {
  getApilog,
  getbalance,
} from "api/apis";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./index.scss";
class InvoiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      data: null,
      order_list: [
        {
          model_capability:'智能简历',
          measure:'askdjk',
          num:'2990',
          price:'0.50',
          total_price:'1495.00'
        },
        {
          model_capability:'智能简历',
          measure:'askdjk',
          num:'2990',
          price:'0.50',
          total_price:'1495.00'
        },
        {
          model_capability:'智能简历',
          measure:'askdjk',
          num:'2990',
          price:'0.50',
          total_price:'1495.00'
        },
 
      ],
      currentDate: '',
      timeHorizon: "",
      balance:'0.00',
      credit_limit:'0.00',
      give_limit:'.00',
      available_limit:"2990.00",
      loading: false,
      no_data: false,
      InvoiceTypeOptions: {
        1: "增值税普通发票",
        2: "增值税专用发票",
      },
      columns: [
        {
          title: "产品名称",
          dataIndex: "model_capability",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
            height: 68,
          },
        },

        {
          title: "接口ID",
          dataIndex: "measure",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "数量",
          dataIndex: "num",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>{formatMoney(col)}</span>,
        },
        {
          title: "单价",
          dataIndex: "price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>￥{formatMoney(col)}</span>,
        },
        {
          title: "总金额",
          dataIndex: "total_price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>￥{formatMoney(col)}</span>,
        },
      ], 
      columns2: [
        {
          title: "产品名称",
          dataIndex: "model_capability",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
            height: 68,
          },
        },
        {
          title: "数量",
          dataIndex: "num",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>{formatMoney(col)}</span>,
        },
        {
          title: "单价",
          dataIndex: "price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>￥{formatMoney(col)}</span>,
        },
        {
          title: "总金额",
          dataIndex: "total_price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>￥{formatMoney(col)}</span>,
        },
      ], 
      record:
        localStorage.getItem("record") &&
        JSON.parse(localStorage.getItem("record")),
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getinit();
  }
  UNSAFE_componentDidUpdate() {}
  getinit() {
    this.getinvoice_list();
  }
  getinvoice_list() {
    let date = this.getPreviousMonth();
    this.setState({
      currentDate:this.getCurrentTime(),
      timeHorizon:this.getMonthRange(),
    })
    getApilog({ date }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        this.setState({
          data: list?.record ? list.record['REAL-TIME'] : [],
          balance: list.amount.used_amount, //充值余额
          give_limit: list.amount.gift_amount, //赠送额度
          credit_limit: list.amount.credit_amount, //信用额度
          loading: false,
          loadingbody: false,
          no_data: list.record && list.record['REAL-TIME'].length > 0 ? false : true,
        });
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
  }
   getPreviousMonth() {
    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();
  
    // 如果当前月份是1月，则回到上一年的12月
    if (month === 0) {
      year -= 1;
      month = 11; // 11代表12月
    } else {
      month -= 1; // 否则直接减1
    }
  
    const formattedMonth = String(month + 1).padStart(2, '0'); // 补零
    return `${year}-${formattedMonth}`;
  }
  
   getCurrentTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}年${month}月${day}日${hours}:${minutes}:${seconds}`;
  }
  
   getMonthRange() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // 获取当前月份，注意月份是从0开始计数的
    const firstDay = new Date(year, month, 1); // 当前月份的第一天
    const lastDay = new Date(year, month + 1, 0); // 当前月份的最后一天
    const formattedFirstDay = `${year}年${String(month + 1).padStart(2, '0')}月01日`;
    const formattedLastDay = `${year}年${String(month + 1).padStart(2, '0')}月${String(lastDay.getDate()).padStart(2, '0')}日`;
    return `${formattedFirstDay}-${formattedLastDay}`;
  }
  

  formRefDate() {
    this.formRef
      .validate()
      .then((res) => {
        this.setState({
          confirmLoading: true,
          isedit: false,
        });
        let obj = res;
        console.log(obj, "obj");
      })
      .catch((error) => {
        let errorslist = error.errors;
        let message = "";
        Object.keys(errorslist ? errorslist : []).map((item, i) => {
          if (errorslist[item].requiredError && i === 0) {
            message = errorslist[item].message;
          }
        });
        Notification.error({ content: message });
      });
  }
  formRefcancel() {
    this.formRef.resetFields();
  }
  formRefedit() {
    this.setState({
      isedit: true,
    });
  }

  onViewDetails() {}
  render() {
    const {
      loadingbody,
      Modalvisible,
      loading,
      columns,
      columns2,
      order_list,
      no_data,
      data,
      balance,
      credit_limit,
      give_limit,
      available_limit,
      currentDate,
      timeHorizon,
    } = this.state;
    return (
      <div className="payment-detail">
        <div className="Header-init">
          <div className="Header-title">费用明细</div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            {no_data ? (
              ""
            ) : (
              <div style={{ marginTop: 30 }}>
                <div className="invoicedetail-card-head">
                  <div className="card-head-left">{currentDate}</div>
                  <div className="card-head-right">
                    <div className="">{timeHorizon}</div>
                    <div className="card-right-descript">接口调用月结</div>
                  </div>
                </div>
                <div className="Table-card">
                  <Table
                    virtualized
                    noDataElement={
                      <Empty
                        imgSrc={Emptydata_img}
                        description={"暂无相关数据"}
                      />
                    }
                    no_data={no_data}
                    border={{ wrapper: false, cell: false }}
                    loading={loading}
                    columns={columns}
                    data={data}
                    pagination={false}
                  />
                  <div className="total-money-detail">
                    <div className="total-money-detail-balance">
                      <div>充值金额</div><div className="digitization">￥{formatMoney(balance)}</div>
                      </div>
                    <div className="total-money-detail-credit_limit">
                      <div>赠送金额</div><div className="digitization">￥{formatMoney(give_limit)}</div>
                      </div>
                    <div className="total-money-detail-give_limit">
                      <div>信用额度</div><div className="digitization">￥{formatMoney(credit_limit)}</div>
                      </div>
                  </div>
                  <div className="total-money-content">
                    <div className="total-money-detail-available_limit">
                      <div>总费用</div><div className="digitization">￥{formatMoney(available_limit)}</div>
                      </div>
                  </div>
                </div>
              </div>
              
            )}

            {no_data ? (
              ""
            ) : (
              <div style={{ marginTop: 30 }}>
                <div className="invoicedetail-card-head">
                  <div className="card-head-left">{currentDate}</div>
                  <div className="card-head-right">
                    <div className="card-right-descript">资源包购买</div>
                  </div>
                </div>
                <div className="Table-card">
                  <Table
                    virtualized
                    noDataElement={
                      <Empty
                        imgSrc={Emptydata_img}
                        description={"暂无相关数据"}
                      />
                    }
                    no_data={no_data}
                    border={{ wrapper: false, cell: false }}
                    loading={loading}
                    columns={columns2}
                    data={order_list}
                    pagination={false}
                  />
                  <div className="total-money-detail">
                    <div className="total-money-detail-balance">
                      <div>充值金额</div><div className="digitization">￥{formatMoney(balance)}</div>
                      </div>
                    <div className="total-money-detail-credit_limit">
                      <div>赠送金额</div><div className="digitization">￥{formatMoney(give_limit)}</div>
                      </div>
                    <div className="total-money-detail-give_limit">
                      <div>信用额度</div><div className="digitization">￥{formatMoney(credit_limit)}</div>
                      </div>
                  </div>
                  <div className="total-money-content">
                    <div className="total-money-detail-available_limit">
                      <div>总费用</div><div className="digitization">￥{formatMoney(available_limit)}</div>
                      </div>
                  </div>
                </div>
              </div>
              
            )}


          </>
        )}

      </div>
    );
  }
}
export default InvoiceDetail;
