import React, { Component } from "react";
import {
  Table,
  Card,
  Typography,
  Button,
  Tabs,
  Divider,
  Radio,
  Space,
  Input,
  Empty,
  Spin,
  Popconfirm,
} from "@arco-design/web-react";
import history from "common/utils/history";
import { getenginepackage, getuserpackage } from "api/apis";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./resources.scss";

// 封装组件
class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      visibleUpgrade: false,
      type: "可使用",
      Tablist: ["可使用", "已用完", "已过期"],
      data: [],
      columns: [
        {
          title: "资源类型",
          dataIndex: "name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },

        // {
        //   title: "API可调用资源数",
        //   dataIndex: "total",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        // },
        {
          title: (
            <>
              剩余可用资源数
              {JSON.parse(localStorage.getItem("userinfo")) &&
              JSON.parse(localStorage.getItem("userinfo")).group_permission &&
              JSON.parse(localStorage.getItem("userinfo")).group_permission !==
                "PERSONAL" ? (
                ""
              ) : (
                <Popconfirm
                  className="Popconfirm-Upgrade"
                  style={{ maxWidth: 382 }}
                  trigger="hover"
                  position="bl"
                  icon={null}
                  title={
                    <div className="rowSpaceAround">
                      <span className="golden_vip_table"></span>
                      <div className="Popconfirm-Upgrade-font">
                        <div className="Popconfirm-Upgrade-title AlibabaPuHuiTi-Medium">
                          开通企业尊享版
                        </div>
                        <div className="Popconfirm-Upgrade-text">
                          可扩充调用次数和获取更多专属特权
                        </div>
                      </div>
                      <button
                        className="Upgrade-btn Upgrade-svp AlibabaPuHuiTi-Medium"
                        onClick={() => this.setvisibleUpgrade(true)}
                      >
                        立即升级
                      </button>
                    </div>
                  }
                >
                  <span className="blue_vip_table"></span>
                </Popconfirm>
              )}
            </>
          ),
          dataIndex: "balance",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          width: 168,
        },
        {
          title: "已调用资源数",
          dataIndex: "used",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        {
          title: "到期时间",
          dataIndex: "expired_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false,
      },
      loading: false,
      no_data: false,
      Selectoptions: JSON.parse(localStorage.getItem("Selectoptions")),
      record: JSON.parse(localStorage.getItem("record")),
      keyword: "",
    };
  }
  componentDidMount() {
    this.getpackage();
  }
  setvisibleUpgrade(value) {
    history.push("/privilege");
    history.go();
  }
  onsetPosition(value) {
    let packagePagination = this.state.pagination;
    packagePagination.current = 1;
    this.setState({
      type: value,
      loading: false,
      pagination: packagePagination,
    });
    this.state.type = value;
    this.getpackage();
  }
  onPressEnter(value) {
    //console.log(value, 'value')
    this.setState({
      keyword: value,
      loading: true,
    });
    this.state.keyword = value;
    setTimeout(() => {
      this.getpackage();
    }, 500);
  }
  getpackage() {
    let page = {
      // per_page: this.state.pagination.pageSize,
      // page: this.state.pagination.current,
      data_type:"detail",
      // status:
      package_type:
        this.state.type === "已用完"
          ? "finish"
          : this.state.type === "已过期"
          ? "expired"
          : "",
      type: this.state.record.packageType,
      // keyword: this.state.keyword,
    };
    getuserpackage(page).then((res) => {
      if (res.code === 200) {
        let no_data = false;
        let list = res.data;
        let packagePagination = this.state.pagination;
        packagePagination.total = list.count;
        if (list.all_count === 0) {
          no_data = true;
        } else {
          no_data = false;
        }
        this.setState({
          no_data: no_data,
          data: list,
          pagination: packagePagination,
          loading: false,
          loadingbody: false,
        });
        //console.log(list, 'list')
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
          data: [],
        });
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      let setPagination = this.state.pagination;
      setPagination.current = current;
      setPagination.pageSize = pageSize;
      this.setState({
        loading: false,
        pagination: setPagination,
      });
      this.getpackage();
    }, 100);
  }
  godetails(record) {
    history.push("/myapp/appdataset");
    localStorage.setItem("record", JSON.stringify(this.state.record));
    window.location.reload();
  }
  gocalldetails() {
    history.push("/myapp/calldetails");
    history.go(0);
  }
  goUpPackage() {
    history.push("/promotion");
    history.go(0);
  }

  render() {
    const {
      loadingbody,
      type,
      Tablist,
      loading,
      data,
      pagination,
      no_data,
      columns,
    } = this.state;
    return (
      <div className="resources">
        <div className="Header-init">
          <div className="Header-title">应用资源</div>
        </div>
        <div className="GroupTable-fixe">
          <Radio.Group
            type="button"
            name="direction"
            value={type}
            onChange={this.onsetPosition.bind(this)}
            options={Tablist}
            className="GroupTable"
          ></Radio.Group>
          <div className="GroupTable-Searchbox">
            <Space size={"medium"}>
              <Input.Search
                style={{ width: 350 }}
                placeholder="快速查询资源列表"
                className="GroupTable-Search"
                onSearch={this.onPressEnter.bind(this)}
              />
              <button
                className="custom-primary"
                onClick={this.goUpPackage.bind(this)}
              >
                升级资源包
              </button>
              {/* <button
                className="custom-White-button"
                onClick={this.gocalldetails.bind(this)}
              >
                调用明细
              </button> */}
            </Space>
          </div>
        </div>
        {loadingbody ? (
          <Spin dot className="table-spin-loading" />
        ) : (
          <>
            {no_data ? (
              <Empty
                imgSrc={Emptydata_img}
                description={"暂无相关资源"}
                style={{ marginTop: 200 }}
              />
            ) : (
              <Card
                bordered={false}
                style={{ width: "100%", borderRadius: 22 }}
              >
                <Table
                  virtualized
                  no_data={no_data}
                  border={{ wrapper: false, cell: false }}
                  noDataElement={
                    <Empty
                      imgSrc={Emptydata_img}
                      description={"暂无相关资源"}
                    />
                  }
                  loading={loading}
                  columns={columns.concat({
                    title: "操作",
                    dataIndex: "operation",
                    render: (col, record, index) => (
                      <Button
                        type="outline"
                        className="outlineTable"
                        onClick={this.godetails.bind(this, record)}
                      >
                        调用详情
                      </Button>
                    ),
                    fixed: "right",
                    width: 150,
                    align: "center",
                  })}
                  data={data}
                  pagination={pagination.total === 0 ? false : pagination}
                  onChange={this.onChangeTable.bind(this)}
                  renderPagination={(paginationNode) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      {paginationNode}
                    </div>
                  )}
                />
              </Card>
            )}
          </>
        )}
      </div>
    );
  }
}
export default Resources;
